<template>
  <div id="about-us">
    <loading :can-cancel="true"></loading>
    <div class="about-us-container">
      <div class="title">{{ data.title }}</div>
      <div class="content">
        {{ data.text }}
      </div>
      <div class="slider-container">
        <div class="slider">
          <AboutUsSlider />
        </div>
        <div class="image-container">
          <img class="image" src="../assets/images/About-me.svg" alt="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import AboutUsSlider from "../components/Cards/AboutUsSlider";
import Footer from "@/components/Footer";
import Loading from "vue-loading-overlay";
import axios from "axios";

export default {
  components: {
    AboutUsSlider,
    Footer,
    Loading
  },
  data() {
    return {
      data: {}
    };
  },
  mounted() {
    this.getAboutUse();
  },
  methods: {
    async getAboutUse() {
      let loader = this.$loading.show();
      try {
        let response = await axios("/about-us");
        this.data = response.data;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    }
  }
};
</script>
<style scoped>
#about-us {
}
.about-us-container {
  padding: 0 10px;
  margin-top: 70px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: right;
  color: #070d59;
  margin-top: 32px;
  font-family: "Vazir Medium FD";
}
.content {
  margin-top: 16px;
  line-height: 50px;
  text-align: right;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.slider-container {
  display: flex;
  justify-content: space-between;
}

.slider {
  width: 100%;
  margin-top: 60px;
}

.image-container {
  display: none;
  width: 100%;
}

/*.slider {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  width: 50%;*/
/*}*/

/*.image {*/
/*  display: none;*/
/*  position: absolute;*/
/*  top: 230px;*/
/*  left: 100px;*/
/*  max-width: 600px;*/
/*  width: 100%;*/
/*}*/
/*.image-container {*/
/*  position: relative;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  flex-grow: 1;*/
/*  max-width: 600px;*/
/*}*/
/*.slider-container {*/
/*  width: 100%;*/
/*  display: flex;*/
/*  !*justify-content: center;*!*/
/*}*/

@media (min-width: 960px) {
  /*.image-container {*/
  /*  position: relative;*/
  /*  display: flex;*/
  /*  justify-content: space-between;*/
  /*  flex-grow: 1;*/
  /*  max-width: 600px;*/
  /*}*/
  /*.slider-container {*/
  /*  width: 100%;*/
  /*  display: flex;*/
  /*  justify-content: center;*/
  /*}*/
  .slider {
    width: 50%;
  }

  .image-container {
    display: block;
    width: 50%;
  }

  .image {
    width: 80%;
  }
}
@media (min-width: 1200px) {
}
</style>
