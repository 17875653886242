<template>
  <div id="card">
    <div class="card-container">
      <img class="image" :src="image" alt="" />
      <div class="header">{{ header }}</div>
      <div class="body">
        {{ body }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServicesCard",
  data() {
    return {};
  },
  props: {
    image: {
      type: String
      //   default: "@/assets/images/icons/PSVouchers.png",
    },
    header: {
      type: String,
      require: true
    },
    body: {
      type: String,
      require: true
    }
  }
};
</script>
<style scoped>
#card {
  width: 100%;
  padding: 10px 10px;
  max-width: 230px;
}
.card-container {
  width: 100%;
  height: 300px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.image {
  align-self: center;
  margin-top: 15px;
}
.header {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: right;
  color: #121212;
  margin-right: 17px;
  margin-top: 15px;
  margin-bottom: 9px;
  margin: auto;
}
.body {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 20px;
  color: #121212;
  text-align: right;
  width: 212px;
  text-align: center;
  width: 100%;
  padding: 0 10px;
  margin: auto;
}
</style>
