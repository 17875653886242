<template>
  <hooper
    style="height: 350px; width: 100%"
    :settings="hooperSettings"
    :center-mode="true"
  >
    <slide v-for="data in ServiceCardData" :key="data.id">
      <ServicesCard
        :image="data.image"
        :header="data.header"
        :body="data.body"
      />
    </slide>
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
  </hooper>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import ServicesCard from "../HomePage/Cards/ServicesCard";

import "hooper/dist/hooper.css";

export default {
  name: "AboutUsSlider",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    ServicesCard
  },
  data() {
    return {
      hooperSettings: {
        rtl: "true",
        itemsToShow: 1,
        centerMode: false,
        breakpoints: {
          768: {
            centerMode: false,
            itemsToShow: 3
          },
          1000: {
            itemsToShow: 3,
            pagination: "fraction"
          }
        }
      },
      ServiceCardData: [
        {
          id: 1,
          image: require("../../assets/images/PSVouchers.png"),
          header: "خدمات رمز ارز ها",
          body:
            "پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید."
        },
        {
          id: 2,
          image: require("../../assets/images/PM.png"),
          header: "خدمات اسکریل",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 3,
          image: require("../../assets/images/PSV.png"),
          header: "خدمات پرفکت مانی",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 4,
          image: require("../../assets/images/FlagBrand=paypal,Mode=Light.png"),
          header: "خرید و فروش پی پال",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 5,
          image: require("../../assets/images/FlagBrand=skrill,Mode=Light.png"),
          header: "خرید و فروش پی پال",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 6,
          image: require("../../assets/images/webmoney.png"),
          header: "خدمات ووچر",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        }
      ]
    };
  }
};
</script>
<style scoped></style>
<style>
/deep/.card-container {
  min-width: 230px;
}
.icon {
  width: 50px !important;
  height: 100px !important;
}
</style>
